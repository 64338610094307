import React from 'react';
import { AcademicCapIcon, GlobeAltIcon } from '@heroicons/react/20/solid';
import Footer from "./Footer";
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations';

const AboutUs = () => {
  const { language, setLanguage } = useLanguage();
  const currentTranslations = translations[language];

  return (
    <div className="bg-gradient-to-b from-[#0D1B1E] to-[#1A302B] text-[#E8DCC8] min-h-screen font-sans w-full flex flex-col pt-[8em] md:pt-[10em]">
      <div className="px-2 md:px-0">
        
        {/* Hero Section */}
        <section className="text-center  px-4">
          <div className="relative w-full md:max-w-screen-xl mx-auto">
            <div className="mb-6">
              <button
                onClick={() => window.history.back()}
                className="flex items-center space-x-2 text-gray-100 hover:text-gray-200 font-medium transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
                <span>{currentTranslations.Back}</span>
              </button>
            </div>
          </div>
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-5xl md:text-7xl font-extrabold font-serif text-[#E8DCC8] mb-8 break-words max-w-full">
              {currentTranslations.About1}
            </h2>
            <p className="text-2xl text-gray-300 max-w-4xl mx-auto">
              {currentTranslations.About2}
            </p>
          </div>
        </section>

        {/* Services Section */}
        <section className="max-w-screen-xl mx-auto py-20">
          <div className="text-center mb-16">
            <h3 className="text-4xl font-bold text-[#A8D9BB] mb-4">
              {currentTranslations.About3}
            </h3>
            <p className="text-lg text-gray-300">
              {currentTranslations.About4}
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
            <div className="bg-[#24393E] rounded-lg p-8 shadow-lg">
              <AcademicCapIcon className="h-16 w-16 text-[#A8D9BB] mb-4" />
              <h3 className="text-3xl font-semibold text-[#E8DCC8] mb-4">
                {currentTranslations.About5}
              </h3>
              <p className="text-lg text-gray-300">
                {currentTranslations.About6}
              </p>
            </div>
            <div className="bg-[#24393E] rounded-lg p-8 shadow-lg">
              <GlobeAltIcon className="h-16 w-16 text-[#A8D9BB] mb-4" />
              <h3 className="text-3xl font-semibold text-[#E8DCC8] mb-4">
                {currentTranslations.About7}
              </h3>
              <p className="text-lg text-gray-300">
                {currentTranslations.About8}
              </p>
            </div>
            <div className="bg-[#24393E] rounded-lg p-8 shadow-lg">
              <GlobeAltIcon className="h-16 w-16 text-[#A8D9BB] mb-4" />
              <h3 className="text-3xl font-semibold text-[#E8DCC8] mb-4">
                {currentTranslations.About9}
              </h3>
              <p className="text-lg text-gray-300">
                {currentTranslations.About10}
              </p>
            </div>
          </div>
        </section>

        {/* Founder and CEO Section */}
        <section className="max-w-screen-xl mx-auto py-20 bg-[#1A2D2D] rounded-lg shadow-lg px-6 md:px-12 mb-8">
          <div className="text-center mb-16">
            <h3 className="text-5xl font-bold text-[#A8D9BB] mb-8 uppercase tracking-wide">
              {currentTranslations.OurTeam}
            </h3>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto leading-relaxed">
              {currentTranslations.TeamDescription}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
            {currentTranslations.Stuff.map((person, index) => (
              <div
                key={index}
                className="bg-[#24393E] rounded-lg shadow-md overflow-hidden p-8 flex flex-col items-center text-center"
              >
                <div className="w-40 h-40 rounded-full overflow-hidden mb-6 border-4 border-[#A8D9BB]">
                  <img
                    src={person.image}
                    alt={person.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-3xl font-bold text-[#E8DCC8] mb-2">
                  {person.name}
                </h3>
                <p className="text-lg text-[#A8D9BB] font-medium mb-4">
                  {person.job}
                </p>
                <p className="text-sm text-gray-400 leading-relaxed">
                  {person.desc}
                  
                </p>
              </div>
            ))}
          </div>
        </section>


        <section className="bg-gradient-to-r from-[#1C3D33] to-[#24393E] py-20 px-4">
          <div className="max-w-screen-lg mx-auto text-center">
            <h2 className="text-5xl font-extrabold font-serif text-[#E8DCC8] mb-8">
              {currentTranslations.About11}
            </h2>
            <p className="text-2xl text-gray-300 max-w-3xl mx-auto">
              {currentTranslations.About12}
            </p>
          </div>
        </section>
      </div>
      <section className="bg-[#0D1B1E] py-20 text-center mt-auto px-2">
        <div className="max-w-screen-lg mx-auto">
          <h2 className="text-5xl font-extrabold font-serif text-[#A8D9BB] mb-8">
            {currentTranslations.About13}
          </h2>
          <p className="text-xl text-gray-300 mb-12">
            {currentTranslations.About14}
          </p>
          <a
            href="/Contacts"
            className="inline-block bg-[#A8D9BB] text-black px-10 py-5 text-xl font-bold rounded-full hover:bg-white transition-colors duration-300"
          >
            {currentTranslations.About13}
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
