import { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations'; // Import the translations

const HeroSection = () => {
  const { language } = useLanguage();
  const currentTranslations = translations[language];

  // const images = [
  //   `${process.env.PUBLIC_URL}/images/mare.png`,
  //   `${process.env.PUBLIC_URL}/images/duomo.png`,
  //   `${process.env.PUBLIC_URL}/images/florence.png`,
  //   `${process.env.PUBLIC_URL}/images/milan.png`,
  // ];

  // const [currentBgIndex, setCurrentBgIndex] = useState(0);
  // const [fadeTransition, setFadeTransition] = useState(false);
  // const intervalRef = useRef(null);

  // const resetInterval = () => {
  //   if (intervalRef.current) {
  //     clearInterval(intervalRef.current);
  //   }
  //   intervalRef.current = setInterval(() => {
  //     handleChangeBackground();
  //   }, 10000);
  // };

  // const handleChangeBackground = () => {
  //   setFadeTransition(true);
  //   setTimeout(() => {
  //     setCurrentBgIndex((prevIndex) => (prevIndex + 1) % images.length);
  //     setFadeTransition(false);
  //   }, 500);
  // };

  // const handleImageClick = () => {
  //   handleChangeBackground();
  //   resetInterval();
  // };

  // const handleCircleClick = (index) => {
  //   setFadeTransition(true);
  //   setTimeout(() => {
  //     setCurrentBgIndex(index);
  //     setFadeTransition(false);
  //   }, 500);
  //   resetInterval();
  // };

  // useEffect(() => {
  //   resetInterval();
  //   return () => clearInterval(intervalRef.current);
  // }, []);

  // const nextImageIndex = (currentBgIndex + 1) % images.length;

  return (
    <div className="relative w-full h-screen overflow-hidden pt-20 bg-black">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/duomo.png)`,
          backgroundSize: 'cover',
          backgroundAttachment: 'scroll',
        }}
      ></div>


      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Main Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
        <h1 className="text-4xl md:text-6xl md:text-7xl font-bold mb-6 max-w-5xl">
          {currentTranslations.HeroSection1}
        </h1>
        <p className="text-lg md:text-2xl mb-8 max-w-xl">
          {currentTranslations.HeroSection2[0]}
          <span className="font-black text-3xl border-2 border-white rounded-xl px-2">{currentTranslations.HeroSection2[1]}</span>
          {currentTranslations.HeroSection2[2]}
        </p>
        <button className="px-10 py-4 bg-white text-[#213E36] font-bold rounded-full transition duration-300 hover:bg-gray-300 shadow-lg" onClick={() => window.location.href = '/Contacts'}>
          {currentTranslations.HeroSection3}
        </button>
      </div>

      {/* Little Image to Change Background */}
      {/* <div className="absolute bottom-10 right-10 hidden lg:block transform hover:scale-105 transition duration-500 ease-in-out z-20" onClick={handleImageClick}>
        <img
          src={images[nextImageIndex]}
          alt="Next Background View"
          className="w-48 h-auto border-4 border-white shadow-xl cursor-pointer"
        />
      </div> */}

      {/* White Circles - Moved to the Left */}
      {/* <div className="absolute bottom-3/4 md:bottom-1/2 left-10 transform translate-y-1/2 block z-20">
        <div className="flex items-center space-x-4">
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() => handleCircleClick(index)}
              className={`transition-all duration-500 ease-in-out ${index === currentBgIndex ? 'w-10 h-10' : 'w-4 h-4'} bg-white rounded-full shadow-md cursor-pointer`}
            ></div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default HeroSection;
