import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body'; // Your main page component
import Unis from './components/Unis'; // New page component
import About from './components/About'; // New page component
import Contacts from './components/Contacts'; // New page component
import Admission from './components/Admission'; // New page component
import Visa from './components/Visa'; // New page component
import CookieConsent from './components/CookieConsent'; // New page component
import Info from './components/Info'; // New page component
import ReviewsPage from './components/ReviewsPage'; // New page component

import { LanguageProvider } from './LanguageContext';
import FloatingCircle from './components/FloatingCircle'; // Import the FloatingCircle component

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Header />
        <CookieConsent />
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/Unis" element={<Unis />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contacts" element={<Contacts />} />
          <Route path="/Admission" element={<Admission />} />
          <Route path="/Visa" element={<Visa />} />
          <Route path="/Info" element={<Info />} />
          <Route path="/ReviewsPage" element={<ReviewsPage />} />
        </Routes>
        <FloatingCircle /> {/* Add the FloatingCircle here */}
        {/* <Footer /> */}
      </Router>
    </LanguageProvider>
  );
}

export default App;
