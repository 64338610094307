import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { FaComments } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { useLanguage } from '../LanguageContext'
import { translations } from '../translations'

const FloatingCircle = () => {
  const { language, setLanguage } = useLanguage();
  const currentTranslations = translations[language];
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [state, handleSubmit, resetForm] = useForm("xrbgvlvy");
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const toggleForm = () => {
    if (!isFormOpen) {
      // Reset form state when reopening
      resetForm();
      setHasSubmitted(false);
    }
    setIsFormOpen(!isFormOpen);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const handleClickOutside = (event) => {
    if (isFormOpen && !event.target.closest(".form-container") && !event.target.closest(".floating-circle")) {
      closeForm();
    }
  };

  useEffect(() => {
    if (isFormOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFormOpen]);

  useEffect(() => {
    if (state.succeeded) {
      setHasSubmitted(true);
    }
  }, [state.succeeded]);

  return (
    <div>
    <div
      onClick={toggleForm}
      className="floating-circle fixed bottom-4 right-4 w-16 h-16 bg-white rounded-full shadow-lg z-[9999] flex items-center justify-center animate-pulse-custom cursor-pointer"
    >
      <FaComments className="text-[#1C3D33] text-3xl" />
      <div className="absolute inset-0 w-full h-full rounded-full border-4 border-blue-500 opacity-50 animate-wave"></div>
      <div className="absolute inset-0 w-full h-full rounded-full border-4 border-blue-500 opacity-50 animate-wave delay-100"></div>
    </div>

    {isFormOpen && (
      <div className="form-container fixed bottom-20 right-4 w-96 bg-white p-8 rounded-lg shadow-lg z-[9999] min-h-[550px] border-2 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          {hasSubmitted ? (
            <h3 className="text-xl font-semibold text-[#1C3D33] text-center w-full flex justify-center items-center h-full">
              {currentTranslations.Circle1[0]}
            </h3>
          ) : (
            <h3 className="text-xl font-semibold">{currentTranslations.Circle2[0]}</h3>
          )}
          <button onClick={closeForm} className="text-[#1C3D33] text-3xl">
            <IoClose />
          </button>
        </div>

        {!hasSubmitted && (
          <form onSubmit={handleSubmit} className="flex flex-col flex-grow">
            <div className="flex-grow">
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1" htmlFor="name">
                  {currentTranslations.Circle3[0]}
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={currentTranslations.Circle4[0]}
                />
                <ValidationError prefix="Name" field="name" errors={state.errors} />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1" htmlFor="contact">
                  {currentTranslations.Circle5[0]}
                </label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={currentTranslations.Circle6[0]}
                />
                <ValidationError prefix="Contact" field="contact" errors={state.errors} />
              </div>
            </div>
            <button
              type="submit"
              disabled={state.submitting}
              className="w-full bg-[#1C3D33] text-white py-3 rounded-lg hover:bg-[#154028] focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
            >
              {state.submitting ? currentTranslations.Circle7[0] : currentTranslations.Circle8[0]}
            </button>
          </form>
        )}
      </div>
    )}
  </div>
  );
};

export default FloatingCircle;
