import React, { useRef, useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { FaWhatsapp, FaTelegram } from 'react-icons/fa'
import Footer from './Footer';
import { useForm, ValidationError } from '@formspree/react';
import { useLanguage } from '../LanguageContext'
import { translations } from '../translations'



const Contacts = () => {
    
  const { language, setLanguage } = useLanguage()
  const currentTranslations = translations[language]
  const formRef = useRef();
  const [state, handleSubmit] = useForm("xrbgvlvy"); // Replace with your Formspree form ID

  const [formData, setFormData] = useState({
    name: '',
    message: '',
    additionalMessage: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setFormData({ name: '', email: '', message: '' });

    try {
      await handleSubmit(event);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  const handleReturnToForm = () => {
    setFormSubmitted(false);
  };

  return (
    <div className="pt-[8em] md:pt-[10em] bg-white">
      <div className="max-w-7xl mx-auto pb-6">
        <div className="relative w-full md:max-w-screen-xl mx-auto">
          <div className="mb-6">
            <button
              onClick={() => window.history.back()}
              className="flex items-center space-x-2 text-gray-800 hover:text-gray-600 font-medium transition-colors duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
              <span>{currentTranslations.Back}</span>
            </button>
          </div>
        </div>
        <div className="relative rounded-lg overflow-hidden shadow-lg m-4 sm:m-0 ">
          <img src={`${process.env.PUBLIC_URL}/images/florence.png`} alt="Contact Us" className="w-full h-[400px] object-cover " />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40">
            <h1 className="text-5xl font-bold text-white tracking-wide">{currentTranslations.Contacts1}</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mt-16">
        <div className="bg-white p-10 rounded-lg shadow-md min-h-[600px] flex items-center justify-center">
            {formSubmitted ? (
              <div className="text-center w-full">
                <p className="text-2xl font-bold text-[#1C3D33] mb-4">{currentTranslations.Contacts8}</p>
                <button
                  onClick={handleReturnToForm}
                  className="mt-4 bg-[#1C3D33] text-[#E8DCC8] py-2 px-6 rounded-lg hover:bg-[#E8DCC8] hover:text-[#1C3D33] transition font-bold"
                >
                  {currentTranslations.Contacts9}
                </button>
              </div>
            ) : (
              <div className="w-full">
                <h2 className="text-4xl font-bold text-[#1C3D33] mb-8">{currentTranslations.Contacts10}</h2>
                <form ref={formRef} onSubmit={handleFormSubmit} className="space-y-8">
                  <div>
                    <label htmlFor="name" className="block text-lg text-[#1C3D33] mb-2">{currentTranslations.Contacts11}</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleFormChange}
                      placeholder={currentTranslations.Circle4}
                      className="w-full p-4 border border-[#E8DCC8] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1C3D33]"
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-lg text-[#1C3D33] mb-2">{currentTranslations.Contacts12[0]}</label>
                    <textarea
                      id="message"
                      name="message"
                      rows="6"
                      value={formData.message}
                      onChange={handleFormChange}
                      placeholder={currentTranslations.Contacts12[1]}
                      className="w-full p-4 border border-[#E8DCC8] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1C3D33]"
                    ></textarea>
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                      className="text-red-500 mt-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="additionalMessage" className="block text-lg text-[#1C3D33] mb-2">{currentTranslations.Contacts16}</label>
                    <textarea
                      id="additionalMessage"
                      name="additionalMessage"
                      rows="6"
                      value={formData.additionalMessage}
                      onChange={handleFormChange}
                      placeholder={currentTranslations.Contacts12[2]}
                      className="w-full p-4 border border-[#E8DCC8] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1C3D33]"
                    ></textarea>
                    <ValidationError
                      prefix="Additional Message"
                      field="additionalMessage"
                      errors={state.errors}
                      className="text-red-500 mt-2"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="w-full bg-[#1C3D33] text-[#E8DCC8] py-4 rounded-lg hover:bg-[#E8DCC8] hover:text-[#1C3D33] transition font-bold"
                  >
                    {state.submitting ? currentTranslations.Contacts14 : currentTranslations.Contacts15}
                  </button>
                </form>


              </div>
            )}
          </div>
          <div className="bg-white p-10 rounded-lg shadow-md">
            <h2 className="text-4xl font-bold text-[#1C3D33] mb-8">{currentTranslations.Contacts2}</h2>
            {/* Social Links Section */}
            <div className="flex my-6 space-x-6">
              <a
                href="https://wa.me/393513554047" // Replace with your WhatsApp number
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#25D366] text-4xl hover:scale-110 transition transform"
              >
                <FaWhatsapp />
              </a>
              <a
                href="https://t.me/+998773091880" // Replace with your Telegram username
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#0088cc] text-4xl hover:scale-110 transition transform"
              >
                <FaTelegram />
              </a>
            </div>
            <div className="space-y-6">
              <div className="flex items-center space-x-4">
                <FaPhoneAlt className="text-3xl text-[#1C3D33]" />
                <p className="text-lg text-[#1C3D33]">{currentTranslations.Contacts3}</p>
              </div>
              <div className="flex items-center space-x-4">
                <FaPhoneAlt className="text-3xl text-[#1C3D33]" />
                <p className="text-lg text-[#1C3D33]">{currentTranslations.Contacts4}</p>
              </div>
              <div className="flex items-center space-x-4">
                <FaEnvelope className="text-3xl text-[#1C3D33]" />
                <p className="text-lg text-[#1C3D33]">
                  {currentTranslations.Contacts5}{" "}
                  <a
                    href="mailto:univermondo@gmail.com"
                    className="underline hover:text-[#1C3D33]"
                  >
                    univermondo@gmail.com
                  </a>
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <FaMapMarkerAlt className="text-3xl text-[#1C3D33]" />
                <p className="text-lg text-[#1C3D33]">{currentTranslations.Contacts7}</p>
              </div>
            </div>
            
          </div>


        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
