import React, { useState, useRef } from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations';
import { FaInstagram} from 'react-icons/fa';
import Footer from "./Footer";

const ReviewsPage = () => {
  const { language } = useLanguage();
  const currentTranslations = translations[language];

  // Ref to track the currently playing video
  const videoRefs = useRef([]);

  // State to track expanded reviews
  const [expandedReviews, setExpandedReviews] = useState({});

  const handlePlay = (index) => {
    // Pause all other videos
    videoRefs.current.forEach((video, idx) => {
      if (video && idx !== index) {
        video.pause();
      }
    });
  };

  const toggleExpand = (index) => {
    setExpandedReviews((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit
      ? { truncated: words.slice(0, wordLimit).join(' ') + '...', full: text }
      : { truncated: text, full: text };
  };

  return (
    <div className="bg-white min-h-screen pt-[8em] md:pt-[10em]">
      <div className="max-w-7xl mx-auto">
      <div className="relative w-full md:max-w-screen-xl mx-auto">
          <div className="mb-6">
            <button
              onClick={() => window.history.back()}
              className="flex items-center space-x-2 text-gray-800 hover:text-gray-600 font-medium transition-colors duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
              <span>{currentTranslations.Back}</span>
            </button>
          </div>
        </div>
        <h1 className="text-5xl font-bold text-[#336659] mb-16 text-center tracking-wide">
          {currentTranslations.RTitle}
        </h1>

        {/* Reviews Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {currentTranslations.Reviews.map((review, index) => {
            const { truncated, full } = truncateText(review.review, 40); // Set word limit to 40
            const isExpanded = expandedReviews[index] || false;

            return (
              <div
                key={index}
                className="bg-white border border-[#E8DCC8] rounded-2xl p-8 shadow-lg flex flex-col justify-between transform transition-transform duration-300"
              >
                {/* Reviewer Image */}
                <div className="w-24 h-24 mx-auto mb-6 rounded-full overflow-hidden border-4 border-[#336659] shadow-lg">
                  <img
                    src={review.image}
                    alt={review.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className='flex items-center justify-center mb-6'>
                  <a className="text-black mr-1">
                    <FaInstagram size={20} />
                  </a>
                  {review.Rinsta}
                </div>
                {/* Review Text */}
                <div className="flex-grow text-center mb-6">
                  <FaQuoteLeft className="text-[#336659] text-3xl mb-4" />
                  <p className="text-gray-800 text-lg italic mb-4 leading-relaxed min-h-[80px]">
                    {isExpanded ? full : truncated}
                  </p>
                  {full !== truncated && (
                    <button
                      onClick={() => toggleExpand(index)}
                      className="text-[#336659] font-bold underline text-sm hover:text-gray-700"
                    >
                      {isExpanded ? currentTranslations.RP1 : currentTranslations.RP2}
                    </button>
                  )}
                  <FaQuoteRight className="text-[#336659] text-3xl mb-4" />
                </div>

                {/* Reviewer Info */}
                <div className="text-center mb-6">
                  <p className="font-bold text-[#336659] text-lg">{review.name}</p>
                  <p className="text-sm text-gray-600">{review.location}</p>
                </div>

                {/* Video Section */}
                {review.video && (
                  <div
                  className="mt-6 rounded-lg overflow-hidden border-4 border-[#336659] bg-[#336659] shadow-md"
                  style={{ aspectRatio: '9/16' }} 
                >
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    onPlay={() => handlePlay(index)}
                    preload="metadata"
                    controls
                    className="w-full h-full object-cover rounded-lg"
                    poster={review.preview}
                  >
                    <source src={review.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                </div>
                
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewsPage;
