import React from 'react';
import { FaFacebookF, FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { FaWhatsapp, FaTelegram } from 'react-icons/fa'
import { useLanguage } from '../LanguageContext'
import { translations } from '../translations'
import { SiVisa, SiMastercard } from 'react-icons/si';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => {
  const { language, setLanguage } = useLanguage();
  const currentTranslations = translations[language];
  return (
    <footer className="bg-[#1C3D33] text-[#E8DCC8] py-16 bottom-0 flex">
  <div className="max-w-7xl mx-auto px-8">
    <div className="flex flex-col md:flex-row justify-between items-center mb-8">
      <div className="flex items-center space-x-4">
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="UniverMondo Logo" className="w-16 h-16" />
        <div>
          <h2 className=" text-3xl md:text-4xl font-serif">{currentTranslations.Footer1[0]}</h2>
          <p className="text-base mt-2">{currentTranslations.Footer2[0]}</p>
        </div>
      </div>
      <div className="flex space-x-6 mt-6 md:mt-0">
        <a href="https://www.instagram.com/univermondo/profilecard/?igsh=c3JlNGdyb2J5b2Jo" className="text-[#E8DCC8] hover:text-white transition">
          <FaInstagram size={28} />
        </a>

      </div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 border-t border-[#E8DCC8] pt-8">
      <div>
        <h3 className="text-2xl font-semibold mb-4">{currentTranslations.Footer3[0]}</h3>
        <p>
          {currentTranslations.Footer4[0]}{' '}
          <a href="mailto:univermondo@gmail.com" className="hover:text-white transition">
            univermondo@gmail.com
          </a>
        </p>
        <p>{currentTranslations.Footer5[0]}</p>
        <p>{currentTranslations.Footer6[0]}</p>
        <div className='flex gap-4 my-4'>
          <a href="https://t.me/+998773091880" 
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#E8DCC8] hover:text-white transition ">
            <FaTelegramPlane size={28} />
          </a>
          <a href="https://wa.me/393513554047" 
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#E8DCC8] hover:text-white transition ">
            <FaWhatsapp size={28} />
          </a>
        </div>
        <div className='flex mt-2'>
          <FaMapMarkerAlt className="text-xl text-[#E8DCC8] mr-2" />
          <p>{currentTranslations.Footer8[0]}</p>
        </div>
      </div>
      <div>
        <h3 className="text-2xl font-semibold mb-4">{currentTranslations.Footer9[0]}</h3>
        <ul className="space-y-2">
          <li>
            <a href="/About" className="hover:text-white transition">
              {currentTranslations.Footer10[0]}
            </a>
          </li>
          <li>
            <a href="/Admission" className="hover:text-white transition">
              {currentTranslations.Footer11[0]}
            </a>
          </li>
          <li>
            <a href="/Contacts" className="hover:text-white transition">
              {currentTranslations.Footer12[0]}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h3 className="text-2xl font-semibold mb-4">{currentTranslations.Footer13[0]}</h3>
        <p>{currentTranslations.Footer14[0]}</p>
        <div className="flex space-x-4 mt-4">
          <a href="https://www.instagram.com/univermondo/profilecard/?igsh=c3JlNGdyb2J5b2Jo" className="text-[#E8DCC8] hover:text-white transition">
            <FaInstagram size={28} />
          </a>
          <a href="https://t.me/+998773091880"
             target="_blank"
             rel="noopener noreferrer"
             className="text-[#E8DCC8] hover:text-white transition">
            <FaTelegramPlane size={28} />
          </a>
          <a href="https://wa.me/393513554047"
             target="_blank"
             rel="noopener noreferrer"
             className="text-[#E8DCC8] hover:text-white transition">
            <FaWhatsapp size={28} />
          </a>
        </div>
      </div>
    </div>
    <div className="flex flex-col md:flex-row justify-between items-center mt-8 pt-6 border-t border-[#E8DCC8]">
      <p className="text-sm">{currentTranslations.Footer15[0].replace('{year}', new Date().getFullYear())}</p>
      <div className="flex items-center space-x-4 mt-4 md:mt-0">
        <SiVisa size={36} className="text-[#E8DCC8]" />
        <SiMastercard size={36} className="text-[#E8DCC8]" />
        <img
          src={`${process.env.PUBLIC_URL}/mir.svg`}
          alt="MIR"
          className="w-9 h-9"
        />
        <img
          src={`${process.env.PUBLIC_URL}/click.svg`}
          alt="Click"
          className="w-9 h-9"
        />
        <img
          src={`${process.env.PUBLIC_URL}/humo.svg`}
          alt="Humo"
          className="w-9 h-9"
        />
      </div>
    </div>
    <div className="flex flex-col md:flex-row justify-center items-center mt-8 space-y-4 md:space-y-0 md:space-x-8">
      <a href="/Info" className="text-sm hover:text-white transition">
        {currentTranslations.Footer16}
      </a>
      <a href="/Info" className="text-sm hover:text-white transition">
        {currentTranslations.Footer17}
      </a>
      <a href="/Info" className="text-sm hover:text-white transition">
        {currentTranslations.Footer18}
      </a>
      <a href="/Info" className="text-sm hover:text-white transition">
        {currentTranslations.Footer19}
      </a>
    </div>
  </div>
</footer>

  );
};

export default Footer;
