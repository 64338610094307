import React from 'react';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations';

const FAQ = () => {
    const { language } = useLanguage()
    const currentTranslations = translations[language]
  const faqData = Object.keys(translations[language]) // Use the current language dynamically
    .filter((key) => key.startsWith('faq')) // Filter only FAQ-related keys
    .map((key) => translations[language][key]); // Map to their corresponding values

  return (
    <div className="space-y-4 max-w-screen-xl mx-auto px-4 mt-4">
      <div className="max-w-screen-2xl mx-auto px-8 text-center">
        <h2 className="text-4xl font-extrabold mb-12 text-[#336659] font-playfair tracking-tight">
          {currentTranslations.que2}
        </h2>
      </div>
      {faqData.map((item, index) => (
        <details key={index} className="group [&_summary::-webkit-details-marker]:hidden">
          <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
            <h2 className="font-medium">{item.question.join(' ')}</h2>
            <svg
              className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </summary>
          <p className="mt-4 px-4 leading-relaxed text-gray-700 whitespace-pre-line">
            {item.answer.join('\n')}
          </p>
        </details>
      ))}
    </div>
  );
};

export default FAQ;
