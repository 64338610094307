import React, { useState } from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useLanguage } from '../LanguageContext';
import { translations } from '../translations';
import { FaInstagram} from 'react-icons/fa';

const Reviews = () => {
  const { language } = useLanguage();
  const currentTranslations = translations[language];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false); // State to toggle text expansion

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? currentTranslations.Reviews.length - 1 : prevIndex - 1
    );
    setIsExpanded(false); // Collapse text when changing reviews
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === currentTranslations.Reviews.length - 1 ? 0 : prevIndex + 1
    );
    setIsExpanded(false); // Collapse text when changing reviews
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit
      ? { truncated: words.slice(0, wordLimit).join(' ') + '...', full: text }
      : { truncated: text, full: text };
  };

  const { truncated, full } = truncateText(
    currentTranslations.Reviews[currentIndex].review,
    40 // Set word limit to 40 words
  );

  return (
    <div className="bg-white py-16 px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-serif text-[#1C3D33] mb-12 text-center">
          {currentTranslations.ReviewsTitle[0]}
        </h2>
        <div className="relative flex items-center justify-center">
          <button onClick={handlePrev} className="absolute left-0 p-2 text-[#1C3D33] z-10">
            <MdChevronLeft size={32} />
          </button>
          <div
            onClick={() => (window.location.href = '/ReviewsPage')}
            className="bg-[#E8DCC8] p-8 rounded-lg shadow-md w-full md:w-2/3 lg:w-1/2 flex flex-col justify-between relative items-center"
          >
            {/* Reviewer Image */}
            <div className="w-24 h-24 mb-2 rounded-full overflow-hidden border-4 border-[#1C3D33]">
              <img
                src={currentTranslations.Reviews[currentIndex].image}
                alt={currentTranslations.Reviews[currentIndex].name}
                className="w-full h-full object-cover object-center"
              />
            </div>
            <div className='flex items-center mb-6'>
              <a className="text-black mr-1">
                <FaInstagram size={20} />
              </a>
              {currentTranslations.Reviews[currentIndex].Rinsta}
            </div>
            <FaQuoteLeft className="text-[#1C3D33] text-3xl mb-4" />
            <p className="text-lg text-[#1C3D33] mb-4 text-center">
              {isExpanded ? full : truncated}
            </p>
            {full !== truncated && (
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent navigation when toggling text
                  setIsExpanded(!isExpanded);
                }}
                className="text-[#1C3D33] font-bold underline text-sm hover:text-gray-700"
              >
                {isExpanded ? currentTranslations.RP1 : currentTranslations.RP2}
              </button>
            )}
            <FaQuoteRight className="text-[#1C3D33] text-3xl mb-4 self-end" />
            <div className="mt-4 text-center">
              <p className="font-bold text-[#1C3D33]">
                {currentTranslations.Reviews[currentIndex].name}
              </p>
              <p className="text-sm text-[#1C3D33]">
                {currentTranslations.Reviews[currentIndex].location}
              </p>
            </div>
          </div>
          <button onClick={handleNext} className="absolute right-0 p-2 text-[#1C3D33]">
            <MdChevronRight size={32} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
